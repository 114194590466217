/**
 * User: Niko
 * Date: 13.03.2020
 * Time: 15:35
 */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from './GlobalConfigContext';
import dayjs from 'dayjs';
import { useSearch } from '../hooks/useSearch';

export const DEFAULT_STATE = {
  query: {
    keywords: '',
    categoryIds: [],
    includeEvents: true,
    includeOfferings: false,
    recurring: true,
    ddmStructureKeys: [],
    excludeUuids: [],
    locale: 'DE_de',
  },
  results: {}
};

const Context = React.createContext(DEFAULT_STATE);
Context.displayName = 'SearchContext';

export const SearchContextProvider = ({ initialQuery, children }) => {
  const { config } = useGlobalConfigContext();
  const dateRangeVal = config.defaultDateRange ? Number.parseInt(config.defaultDateRange) : 30;
  const startDate = config.defaultStartDate ? Date.parse(config.defaultStartDate) : Date.now();
  const [state, setState] = useState({
    ...DEFAULT_STATE,
    query: {
      ...DEFAULT_STATE.query,
      dateRangeVal,
      calendarIds: (config.calendarId && config.calendarId.split(',')) || null,   //null = all calendar
      startTime: dayjs(startDate).startOf('day').valueOf(),
      endTime: dayjs(startDate).add(dateRangeVal, 'days').endOf('day').valueOf(),
      ...initialQuery
    }
  });
  const [{ data, isLoading, isError, success }, search] = useSearch(state.query);

  useEffect(() => {
    const dateRangeVal = config.defaultDateRange ? Number.parseInt(config.defaultDateRange) : 30;
    setState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        dateRangeVal,
        calendarIds: (config.calendarId && config.calendarId.split(',')) || null,   //null = all calendar
      }
    }));
  }, [config]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      results: data
    }));
  }, [data]);

  const value = useMemo(() => ({
    ...state,
    setState,
    isLoading,
    isError,
    success,
    search
  }), [state, setState, isLoading, isError, success, search]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

SearchContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const useSearchContext = () => {
  const { setState, ...rest } = useContext(Context);

  const setQuery = useCallback((query) => {
    setState(prevSate => ({
      ...prevSate,
      query,
    }));
  }, [setState]);

  return ({
    setState,
    setQuery,
    ...rest
  });
};
