import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './event-ui-portlet';
import {lrc} from './utils/lr-client-instance';
import { version } from '../package.json';

const DEFAULT_CONFIG = {
  //widget: 'EventMapWidget',
  widget: 'EventCalendarWidget',
  //widget: 'EventDetailsWidget',
  // widget: 'SingleEventWidget',
  // widget: 'CurrentEventsWidget',
  rootElement: 'root',
  config: {
    portletSetupTitle_de_DE: 'Hier sehen wer mitmacht',
    introductionText_de_DE: 'Wir freuen uns auf viele Akteure, die mit ihren Veranstaltungen die Energiewendetage bereichern. Energiewendetage Baden-Württemberg 2020. Wir sind Energie!',
    // calendarId: '42461', // unser nackar
    // calendarId: '32261', // mit anmeldeformular
    // calendarId: '32905', // mit anmeldeformular
    calendarId: '40433', // veranstaltungen bw
    // eventId: '136714',
    // eventId: '459171',
    // eventId: '533756',
    // eventId: '461118',
    eventId: '670534',
    // defaultDateRange: 830,
    defaultDateRange: 365,
    defaultStartDate: '2022-01-01',
    numberOfDays: 365,
    baseUrl: '/',
    geolocation: true,
    // variant: 'default',
    // variant: 'energiewendetage',
    variant: 'bitbw',
    portletId: 'event_portlet',
    eventCalendarViewPath: 'http://localhost:3000',
    location: true,
    description: true,
    participationfee: true,
    groupofparticipants: true,
    organizer: true,
    city: true,
    links: true,
    emptyResultsImage: 'http://localhost:8081/o/um-lr73-base-theme/images/sad_search_results.gif',
    showImage: true,
    singleAppointment: true
  },
};

export const renderPortlet = (config) => {
  config = { ...DEFAULT_CONFIG, ...config };
  const rootElement = document.getElementById(config.rootElement || 'root');

  if (config.config && config.config.sameServer === "true") {
    lrc.options.useBasicAuth = false;
  }

  return (
    ReactDOM.render(
      <React.StrictMode>
        <span dangerouslySetInnerHTML={{__html: `<!-- event-management-ui // version: ${version} -->`}}></span>
        <App {...config}/>
      </React.StrictMode>,
      rootElement
    )
  );


};
window.renderPortlet = renderPortlet;
