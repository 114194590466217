/**
 * Created by Nikolai Gorte
 * User: n.gorte on 28.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, {createRef} from 'react';
import PropTypes from 'prop-types';

import { MapContainer, TileLayer, ZoomControl, Marker } from 'react-leaflet';

import markerIconPng from "../../style/components/leaftlet/images/marker-icon.png";

import {Icon, latLngBounds} from 'leaflet'
import { CustomLink } from '../CustomLink';

import { GestureHandling } from "leaflet-gesture-handling";
import "../../style/components/leaftlet/leaflet-gesture-handling.css";
import * as L from 'leaflet';

export class ResultsMapDefault extends React.Component {

  constructor (props) {
    super(props);

    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    this.state = {
      selectedEvent: null
    };

    this.MAP_ID = 'map';

    this.markers = [];

    this.mapRef = createRef();
    this.mapReady = false;

    this.onSelectEvent = this.onSelectEvent.bind(this);
  }

  componentDidUpdate (prevProps) {
    const { selectedEvent } = this.state;

    if (! selectedEvent) {
      this.fitBounds();
    }
  }

  onSelectEvent (event) {
    this.setState({
      selectedEvent: event
    });
  }

  fitBounds() {
    if (this.mapReady && this.mapRef) {
      const bounds = this.getBounds();

      if (bounds && bounds.isValid()) {
        this.mapRef.current.fitBounds(bounds);
      }
    }
  }

  getBounds() {
    const { data } = this.props;

    let positions = [];

    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].geolocation != null) {
          let position = [data[i].geolocation.latitude, data[i].geolocation.longitude];

          positions.push(position);
        }
      }
    }

    return latLngBounds(positions);
  }

  render () {
    const { data } = this.props;

    const { selectedEvent } = this.state;

    let countGeolocations = 0;

    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].geolocation != null) {
          countGeolocations++;
        }
      }
    }

    if (countGeolocations === 0) {
      this.mapReady = false;
    }

    const icon = new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]});

    let latitude, longitude, routeURL;
    if (selectedEvent) {
      latitude = selectedEvent.geolocation.latitude;
      longitude = selectedEvent.geolocation.longitude;
      routeURL = `https://maps.google.de/maps?daddr=${latitude},${longitude}&t=h&om=0&hl=de`;
    }

    return (
      <div className="event-results-map">
        {
          (!data || data.length === 0 || (data.length > 0 && countGeolocations === 0)) &&
          <span className="event-results-no-geolocation">Keine Ergebnisse mit Standort gefunden.</span>
        }
        {
          (data && data.length > 0 && countGeolocations > 0) &&
          <div id="map" role="application">
            <MapContainer
              center={[50.97671168332674,10.629850848572232]}
              zoom={8}
              scrollWheelZoom={true}
              zoomControl={false}
              whenCreated={ map => {this.mapRef.current = map; this.mapReady = true; this.fitBounds(); } }
              bounds={this.getBounds()}
              boundsOptions={ { padding: [20, 20] } }
              gestureHandling={true}
              gestureHandlingOptions={
                {
                  text: {
                    touch: "Verschieben der Karte mit zwei Fingern",
                    scroll: "Verwende Strg + Scrollen zum Zoomen der Karte",
                    scrollMac: "Verwende \u2318 + Scrollen zum Zoomen der Karte"
                  },
                  duration: 2000
                }
              }
            >
              <TileLayer
                attribution='&copy; <a href="http://www.bkg.bund.de" target="_blank">Bundesamt für Kartographie und Geodäsie (2022)</a>, <a href="https://sgx.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.html" target="_blank">Datenquellen</a>'
                url="https://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web_light/default/WEBMERCATOR/{z}/{y}/{x}.png"
              />

              <ZoomControl position="bottomright" />

              {
                data.map(event => {
                  const { geolocation } = event;

                  return (
                    geolocation &&
                      <Marker
                        key={event.uuid}
                        position={[geolocation.latitude, geolocation.longitude]}
                        icon={icon}
                        title={event.short_title || event.title}
                        eventHandlers={{
                          click: (e) => {
                            this.onSelectEvent(event);
                          }
                        }}
                      />
                  );
                })
              }

              {
                selectedEvent &&
                <div className="leaflet-top leaflet-left">
                  <div className="leaflet-control">
                    <div className="event-info-wrapper">
                      {
                        latitude && longitude &&
                        <div className="event-info">
                          <div className="event-info-content">
                            <div className="event-title">{selectedEvent && (selectedEvent.short_title || selectedEvent.title)}</div>
                            <CustomLink
                              className={"event-info-link"}
                              to={`/-/kalender/event/${selectedEvent.uuid}`}
                              ariaLabel={`Zur Veranstaltung: ${selectedEvent.title}`}
                              title={`Zur Veranstaltung: ${selectedEvent.title}`}
                            >
                              Zum Event
                            </CustomLink>
                          </div>


                          <a href={routeURL} target="_blank" className="event-info-icons icon-small">
                                    <span className="xdicon">
                                        <span className="icon icon-nav"/>
                                    </span>
                            <span>Route</span>
                          </a>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }

            </MapContainer>

          </div>
        }
      </div>
    );
  }
}

ResultsMapDefault.propTypes = {
  data: PropTypes.array,
  onSelectEvent: PropTypes.func,
};

export default ResultsMapDefault;