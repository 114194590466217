/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */


import React from 'react';
import dayjs from 'dayjs';
import { isTrue } from '../../utils/utils';
import { Icon } from '../Icon';


const EventMetaDefault = ({ event, config, structureFields }) => {

  const dateEnrolStart = dayjs(event.date_enrol_start);
  const dateEnrolEnd = dayjs(event.date_enrol_end);

  if (Object.keys(event).length === 0) {
    return null;
  }
  return (
    <table>
      <tbody>
      {
        isTrue(config.duration) && !event.singleAppointments &&
        <tr>
          <th valign="top">Dauer:</th>
          <td>{event.allDay ? 'Ganztägig' : calcDuration(event.duration)}</td>
        </tr>
      }

      {
        isTrue(config.singleAppointment) && event.singleAppointments &&
        <tr>
          <th valign="top">Einzeltermine:</th>
          <td>
            <ul className="link-list icon-tiny">
              {
                event.singleAppointmentStartTime.map((item, i) => (
                    <li className="link-item">
                      {`${dayjs(item).format('DD.MM.YYYY, HH:mm')} Uhr bis ${dayjs(event.singleAppointmentEndTime[i]).format('HH:mm')} Uhr`}
                    </li>
                  )
                )
              }
            </ul>
          </td>
        </tr>
      }

      {
        isTrue(config.location) && event.location &&
        <tr>
          <th valign="top">Ort:</th>
          <td>{event.location}</td>
        </tr>
      }

      {
        (isTrue(config.isFree) || isTrue(config.participationfee)) && (event.is_free || event.participation_fee || event.cost_url) &&
        <tr>
          <th valign="top">Teilnahmegebühr:</th>
          {event.is_free ?
            <td>Kostenlos</td> :
            event.participation_fee ?
              <td>{event.participation_fee}</td> :
              <td>
                <a
                  className="icon-tiny"
                  href={event.cost_url}
                >
                  <Icon icon="custom-chevron-right"/>
                  Preisübersicht
                </a>
              </td>}

        </tr>
      }


      {
        isTrue(config.minage) && event.min_age >= 0 &&
        <tr>
          <th valign="top">Mindestalter:</th>
          <td>{targetGroupString(event.min_age)}</td>
        </tr>
      }

      {
        isTrue(config.groupofparticipants) && event.group_of_participants &&
        <tr>
          <th valign="top">Teilnehmerkreis:</th>
          <td>{event.group_of_participants}</td>
        </tr>
      }

      {
        isTrue(config.daysenrolend) && event.registrationPossible && event.require_registration && event.date_enrol_end &&
        <tr>
          <th valign="top">Anmeldung:</th>
          <td>{`${dateEnrolStart.format('dddd, DD.MM.YYYY')}, bis ${dateEnrolEnd.format('dddd, DD.MM.YYYY')}`}</td>
        </tr>
      }

      {
        isTrue(config.food) && event.food &&
        <tr>
          <th valign="top">Essen & Trinken:</th>
          <td>{event.food}</td>
        </tr>
      }

      {
        isTrue(config.food_cost) && event.food && event.food_cost &&
        <tr>
          <th valign="top">Kosten(Essen & Trinken)</th>
          <td>{event.food_cost}</td>
        </tr>
      }

      {
        event.vocabularies && event.vocabularies.map(v => {
          if (isTrue(config[v.id])) {
            return (
              <tr key={v.id}>
                <th valign="top">{v.label}</th>
                <td>{v.categories.map(c => {
                  return c.label;
                }).join(', ')}</td>
              </tr>
            );
          }
        })
      }

      {
        structureFields && structureFields.custom && structureFields.custom.map(f => {
          if (isTrue(config[f.key]) && event[f.key]) {
            return (
              <tr key={f.key}>
                <th valign="top">{f.label}</th>
                <td>{event[f.key]}</td>
              </tr>
            );
          }
        })
      }

      {
        isTrue(config.freeplaces) && event.registrationPossible &&
        <tr className="freePlaces">
          <td colSpan={2}>
                        <span className="xdicon">
                            <span
                              className={'status status--' + getFreePlacesState(event.freePlaces, false)}/>
                        </span>
            {getFreePlacesString(event.freePlaces)}
          </td>
        </tr>
      }
      {
        isTrue(config.freeplaces) && event.registrationPossible && event.waitingListFreePlaces > 0 &&
        <tr className="waitingListFreePlaces">
          <td colSpan={2}>
                        <span className="xdicon">
                            <span
                              className={'status status--' + getFreePlacesState(event.waitingListFreePlaces, event.waitingList)}/>
                        </span>
            {getWaitingListFreePlacesString(event.waitingListFreePlaces)}
          </td>
        </tr>
      }
      </tbody>
    </table>
  );

};

export default EventMetaDefault;

const calcDuration = (duration) => {
  const tempTime = dayjs.duration(parseInt(duration));
  const hours = tempTime.asHours();

  if (hours < 1) {
    return `${tempTime.asMinutes()} Minuten`;
  } else if (hours === 1) {
    return 'Eine Stunde';
  } else if (hours % 1 !== 0) {
    return `${tempTime.hours()}:${tempTime.minutes()} Stunden`;
  }

  return `${hours} h`;
};

const targetGroupString = (age) => {
  age = parseInt(age);
  if (age === 0) {
    return 'Ohne Altersbeschränkung';
  } else {
    return `ab ${age} Jahren`;
  }
};

const getFreePlacesString = (freePlaces) => {
  freePlaces = parseInt(freePlaces);
  if (freePlaces < 0) {
    return 'Unbegrenzt';
  } else if (freePlaces === 0) {
    return 'Keine freien Plätze mehr!';
  } else if (freePlaces === 1) {
    return 'Noch 1 Platz frei';
  } else if (freePlaces > 1) {
    return `Noch ${freePlaces} Plätze frei`;
  }
};

const getWaitingListFreePlacesString = (waitingListFreePlaces) => {
  waitingListFreePlaces = parseInt(waitingListFreePlaces);
  if (waitingListFreePlaces === 1) {
    return 'Noch 1 Platz frei auf der Warteliste';
  } else if (waitingListFreePlaces > 1) {
    return `Noch ${waitingListFreePlaces} Plätze frei auf der Warteliste`;
  }
};

const getFreePlacesState = (freePlaces) => {
  freePlaces = parseInt(freePlaces);
  if (freePlaces < 0) {
    return 'green';
  } else if (freePlaces === 0) {
    return 'red';
  } else if (freePlaces > 0 && freePlaces <= 3) {
    return 'yellow';
  } else if (freePlaces > 3) {
    return 'green';
  }
};

