import React from 'react';

import {QueryParams, withDefault, ArrayParam} from 'use-query-params';

const FilterListItem = ({ category, facetKey, index, onFilterClick }) => {
  const queryConfig = {
    categoryIds: withDefault(ArrayParam, [])
  };

  const value = category.label;
  const title = category.label + (category.description ? ': ' + category.description : '');

  return (
    <QueryParams config={queryConfig}>
        {({ query, setQuery }) => {
          const { categoryIds } = query;

          const checked = categoryIds && categoryIds.indexOf(category.id) !== -1;

          return (
            <li
              key={`filter-${facetKey}-${category.id}`}
              title={title}
            >
              <input
                className="styled-checkbox"
                id={`styled-checkbox-${facetKey}-${index}`}
                type="checkbox"
                value={category.id}
                checked={checked}
                onClick={(event) => {
                  if (event.target.checked) {
                    setQuery(
                      { categoryIds: [...categoryIds, category.id]}
                    )
                  } else {
                    setQuery(
                      { categoryIds: categoryIds.filter(item => item !== category.id) }
                    )
                  }

                  onFilterClick(category.id);
                }}
              />

              <label htmlFor={`styled-checkbox-${facetKey}-${index}`}>
                {value}
              </label>
            </li>
          );
        }}
    </QueryParams>
  )
}

export default FilterListItem;